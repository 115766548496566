import { parse } from 'csv-parse'

export const csv = (raw) => new Promise((resolve, reject) => {
  parse(raw, {cast_date: true}, (err, csv) => {
    if (err) {
      reject(err)
      return
    }

    resolve(csv)
  })
})

export const timeSeries = (raw) => new Promise((resolve, reject) => {
  parse(raw, {cast_date: true}, (err, csv) => {
    if (err) {
      reject(err)
      return
    }

    // Remove header
    let data = csv.slice(1).map(v => {
      return [Date.parse(v[0] + " UTC"), parseInt(v[1])]
    })

    // Sort by date
    data.sort((a, b) => a[0] - b[0])

    resolve(uniq(data))
  })
})

export const uniq = (raw) => {
  const obj = {};
  for (let i = 0, len = raw.length; i < len; i++) {
    const key = raw[i][0]
    if (obj[key]) {
      console.log('Removed: ', raw[i], obj[key])
    }

    obj[key] = raw[i];
  }

  const result = []
  for (const key in obj) {
    result.push(obj[key])
  }
  return result
}
