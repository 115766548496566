import React from 'react';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {APIRequests} from "../ChartArea";
import {RequestShare, VersionMonthly} from "../ChartPie";
import {styled} from "@mui/material/styles";
import {useLocation} from "react-router-dom";

const description = {
  r: 'API Requests Processed',
  n: 'Nodes Alive',
  c: 'Deployments Alive',
}

const PaperStyled = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(2)
}));

const Project = ({title, hits, instances, deployments, hitsPerDeployment, hitsPerInstance, versionsPerMonth}) => {
  const location = useLocation();
  let dt, showDate, showYear, showMonth;

  if (location.state && location.state.date !== undefined) {
    dt = new Date(location.state.date)
    showYear = dt.getFullYear()
    showMonth = dt.getMonth() + 1
    showDate = location.state.date
  } else {
    dt = new Date()
    showYear = dt.getFullYear()
    showMonth = dt.getMonth() + 1
    showDate = `${showYear}-${showMonth}`
  }

  const vw = (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) - 240 - 96) / 2

  return (
    <div>
      <Typography variant="h2" align="center">ORY {title}</Typography>
      <PaperStyled>
        <Grid container>
          <Grid item lg={6}>
            <Grid container justify="center">
              <APIRequests title={`${description.r}`}
                           containerWidth={vw}
                           src={hits.replace("{date}", showDate)}
                           year={showYear}
                           month={showMonth} />
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <APIRequests title={`${description.n}`}
                           containerWidth={vw}
                           src={instances.replace("{date}", showDate)}
                           year={showYear}
                           month={showMonth} />
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <APIRequests title={`${description.c}`}
                           containerWidth={vw}
                           src={deployments.replace("{date}", showDate)}
                           year={showYear}
                           month={showMonth} />
            </Grid>
          </Grid>
        </Grid>
      </PaperStyled>
      <PaperStyled>
        <Grid container>
          <Grid item lg={6}>
            <Grid container justify="center">
              <RequestShare title={`Top 25 clusters by API requests`}
                            containerWidth={vw}
                            src={hitsPerDeployment.replace("{date}", showDate)}
                            year={showYear}
                            month={showMonth} />
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <RequestShare title={`Top 25 nodes by API requests`}
                            containerWidth={vw}
                            src={hitsPerInstance.replace("{date}", showDate)}
                            year={showYear}
                            month={showMonth} />
            </Grid>
          </Grid>
        </Grid>
      </PaperStyled>
      <PaperStyled>
        <Grid container>
          <Grid item lg={12}>
            <Grid container justify="center">
              <VersionMonthly title={`Active service versions`}
                              containerWidth={vw}
                              src={versionsPerMonth.replace("{date}", showDate)}
                              year={showYear}
                              month={showMonth} />
            </Grid>
          </Grid>
        </Grid>
      </PaperStyled>
    </div>
  )
}

export default Project
