import React, {Component} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {csv} from "../../helper/parse"

require('highcharts/modules/histogram-bellcurve')(Highcharts)

const HighChartsComponent = ({series, width, title = 'Share'}) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: width,
        height: Math.floor((width * 9) / 16)
      },
      title: {
        text: title,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },
      series: [{
        name: 'Hits',
        colorByPoint: true,
        data: series
      }]
    }}
  />
)

export class RequestShare extends Component {
  state = {
    series: []
  }

  fetchData() {
    const {limit = 1000000, shorten = 10} = this.props
    fetch(this.props.src)
      .then((r) => r.text())
      .then(csv)
      .then((data) => {
        // Slice off header of the CSV file
        const series = data.slice(1)
          .map(v => ({name: v[0].slice(0, shorten), y: parseInt(v[1])}))
          .filter(({y}) => y > limit)

        series.sort((a, b) => b.y - a.y)

        this.setState({
          series: series.slice(0, 25)
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.fetchData()
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    if (this.state.series.length === 0) {
      return null
    }
    return (
      <HighChartsComponent
        width={this.props.containerWidth}
        title={this.props.title}
        series={this.state.series}
      />
    )
  }
}

export class VersionMonthly extends Component {
  state = {
    series: [],
    categories: []
  }

  fetchData() {
    // const { limit = 1000000, shorten = 10 } = this.props
    fetch(this.props.src)
      .then((r) => r.text())
      .then(csv)
      .then((data) => {
        const sorted = data.slice(1)
          .filter(v => {
            let date = new Date(v[0]);
            return date.getFullYear() === this.props.year && date.getMonth() + 1 === this.props.month
          })
          .shift()

        const versions = data[0].slice(1)

        const series = versions.map((version, index) => ({
          name: version,
          y: parseInt(sorted[index + 1]),
        })).sort((a, b) => b.y - a.y)

        this.setState({
          series: series
        })
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.fetchData()
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    if (this.state.series.length === 0) {
      return null
    }

    return (
      <HighChartsComponent
        width={this.props.containerWidth}
        title={this.props.title}
        series={this.state.series}
      />
    )
  }
}
