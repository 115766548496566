import React from 'react';
import {createRoot} from 'react-dom/client';
import "./index.css"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Layout from "./Components/Layout";
import Project from "./Components/Project";
import Dashboard from "./Components/Dashboard";
import Github from "./Components/Github";

const requests = {
  hydra: {
    title: "Hydra",
    hits: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/page-hits-per-day.csv',
    instances: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/identify-service-instances-per-day.csv',
    deployments: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/identify-service-deployments-per-day.csv',
    hitsPerDeployment: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/page-hits-per-deployment-per-month.csv',
    hitsPerInstance: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/page-hits-per-instance-per-month.csv',
    versionsPerMonth: 'https://storage.googleapis.com/metrics-dashboard-public/ory-hydra/ory-hydra-{date}/identify-versions-per-month.csv',
  },
  oathkeeper: {
    title: "Oathkeeper",
    hits: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/page-hits-per-day.csv',
    instances: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/identify-service-instances-per-day.csv',
    deployments: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/identify-service-deployments-per-day.csv',
    hitsPerDeployment: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/page-hits-per-deployment-per-month.csv',
    hitsPerInstance: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/page-hits-per-instance-per-month.csv',
    versionsPerMonth: 'https://storage.googleapis.com/metrics-dashboard-public/ory-oathkeeper/ory-oathkeeper-{date}/identify-versions-per-month.csv',
  },
  kratos: {
    title: "Kratos",
    hits: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/page-hits-per-day.csv',
    instances: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/identify-service-instances-per-day.csv',
    deployments: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/identify-service-deployments-per-day.csv',
    hitsPerDeployment: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/page-hits-per-deployment-per-month.csv',
    hitsPerInstance: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/page-hits-per-instance-per-month.csv',
    versionsPerMonth: 'https://storage.googleapis.com/metrics-dashboard-public/ory-kratos/ory-kratos-{date}/identify-versions-per-month.csv',
  },
  keto: {
    title: "Keto",
    hits: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/page-hits-per-day.csv',
    instances: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/identify-service-instances-per-day.csv',
    deployments: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/identify-service-deployments-per-day.csv',
    hitsPerDeployment: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/page-hits-per-deployment-per-month.csv',
    hitsPerInstance: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/page-hits-per-instance-per-month.csv',
    versionsPerMonth: 'https://storage.googleapis.com/metrics-dashboard-public/ory-keto/ory-keto-{date}/identify-versions-per-month.csv',
  }
}

const root = createRoot(document.getElementById('root'));
root.render((
  <Router>
    <Layout>
      <Routes>
        <Route path="/hydra" element={<Project {...requests.hydra} />}/>
        <Route path="/keto" element={<Project {...requests.keto} />}/>
        <Route path="/kratos" element={<Project {...requests.kratos} />}/>
        <Route path="/oathkeeper" element={<Project {...requests.oathkeeper} />}/>
        <Route path="/" element={<Dashboard requests={Object.keys(requests).map((key) => requests[key])}/>}/>
        <Route path="/github" element={<Github />}/>
      </Routes>
    </Layout>
  </Router>
));
