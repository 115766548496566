import React from 'react';
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'

const PaperStyled = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(2)
}));

const Github = () => {
  return (
    <div>
      <Typography variant="h4" align="center">
        GitHub Stars
      </Typography>
      <PaperStyled>
        <Grid container>
          <Grid item lg={12}>
            <Grid container justify="center">
              <Typography variant="h4" align="center">ORY Total</Typography>
              <img alt={"ORY GitHub Stars"} style={{width: '100%', height: 'auto'}}
                   src={"https://github-trends-ijakee5waq-ew.a.run.app/stars.svg?user=ory"}/>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <Typography variant="h4" align="center">ORY Hydra</Typography>
              <img alt={"ORY Hydra GitHub Stars"} style={{width: '100%', height: 'auto'}}
                   src={"https://starchart.cc/ory/hydra.svg"}/>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <Typography variant="h4" align="center">ORY Oathkeeper</Typography>
              <img alt={"ORY Oathkeeper GitHub Stars"} style={{width: '100%', height: 'auto'}}
                   src={"https://starchart.cc/ory/oathkeeper.svg"}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6}>
            <Grid container justify="center">
              <Typography variant="h4" align="center">ORY Keto</Typography>
              <img alt={"ORY Keto GitHub Stars"} style={{width: '100%', height: 'auto'}}
                   src={"https://starchart.cc/ory/keto.svg"}/>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid container justify="center">
              <Typography variant="h4" align="center">ORY Kratos</Typography>
              <img alt={"ORY Kratos GitHub Stars"} style={{width: '100%', height: 'auto'}}
                   src={"https://starchart.cc/ory/kratos.svg"}/>
            </Grid>
          </Grid>
        </Grid>
      </PaperStyled>
    </div>
  )
}

export default Github
