import React from 'react';
import {APIRequestsCollective} from '../ChartArea'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import {useLocation} from "react-router-dom";

const description = {
  r: 'API Requests Processed',
  n: 'Instances Alive',
  c: 'Deployments Alive',
}

const PaperStyled = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(2)
}));

const Dashboard = ({requests}) => {
  const location = useLocation();
  let dt, showDate, showYear, showMonth;

  if (location.state && location.state.date !== undefined) {
    dt = new Date(location.state.date)
    showYear = dt.getFullYear()
    showMonth = dt.getMonth() + 1
    showDate = location.state.date
  } else {
    dt = new Date()
    showYear = dt.getFullYear()
    showMonth = dt.getMonth() + 1
    showDate = `${showYear}-${showMonth}`
  }

  const vw = (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) - 240 - 96) / 2

  return (
    <div>
      <Typography variant="h4" align="center">
        Open Source Ecosystem Telemetry
      </Typography>
      <PaperStyled>
        <Grid container>
          <Grid item lg={12}>
            <Grid container justify="center">
              <APIRequestsCollective title={description.r}
                                     containerWidth={vw}
                                     src={requests.map(({hits}) => hits.replace("{date}", showDate))}
                                     year={showYear}
                                     month={showMonth} />
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container justify="center">
              <APIRequestsCollective title={description.n}
                                     containerWidth={vw}
                                     src={requests.map(({instances}) => instances.replace("{date}", showDate))}
                                     year={showYear}
                                     month={showMonth} />
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container justify="center">
              <APIRequestsCollective title={description.c}
                                     containerWidth={vw}
                                     src={requests.map(({deployments}) => deployments.replace("{date}", showDate))}
                                     year={showYear}
                                     month={showMonth} />
            </Grid>
          </Grid>
        </Grid>
      </PaperStyled>
    </div>
  )
}

export default Dashboard
