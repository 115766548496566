import React, {Component} from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {timeSeries} from "../../helper/parse"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import numeral from 'numeral'

const HighChartsComponent = ({width, series, title}) => (
  <HighchartsReact
    highcharts={Highcharts}
    oneToOne={true}
    options={{
      chart: {
        zoomType: 'x',
        width: width,
        height: Math.floor((width * 9) / 16)
      },
      title: {
        text: title
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Requests'
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        type: 'area',
        data: series
      }]
    }}
  />
)

export class APIRequests extends Component {
  state = {
    series: [],
    sum: 0
  }

  fetchData() {
    const minDate = Date.UTC(this.props.year, this.props.month - 1, 1)
    const maxDate = Date.UTC(this.props.year, this.props.month, 0)

    fetch(this.props.src)
      .then((r) => r.text())
      .then(timeSeries)
      .then((series = []) => {
        // Filter out items that does not land to the requested month
        series = series.filter(v => {
          return v[0] >= minDate && v[0] <= maxDate
        })

        const state = {
          series: series,
          sum: series.reduce((acc, next) => acc + next[1], 0)
        }

        state.series.sort((a, b) => a[0] - b[0])
        this.setState(state)
      })
      .catch(e => {
        console.error(e)
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.fetchData()
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    if (this.state.series.length === 0) {
      return null
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center">
            <HighChartsComponent
              width={this.props.containerWidth}
              title={this.props.title}
              series={this.state.series}/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Typography variant="body2">
              Total: {numeral(this.state.sum).format('0.00a')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export class APIRequestsCollective extends Component {
  state = {
    series: [],
    growth: [],
    avg: 0
  }

  fetchData() {
    const loaded = [];
    const minDate = Date.UTC(this.props.year, this.props.month - 1, 1)
    const maxDate = Date.UTC(this.props.year, this.props.month, 0)

    this.props.src.forEach((src) => {
      fetch(src)
        .then((r) => r.text())
        // If fetch errored then timeSeries will parse to single event and header is sliced so empty array is returned
        .then(timeSeries)
        .then((series) => {
          // Filter out items that does not land to the requested month
          loaded.push(series.filter(v => {
            return v[0] >= minDate && v[0] <= maxDate
          }))

          if (loaded.length === this.props.src.length) {
            this.parseData(loaded)
          }
        })
        .catch(e => {
          console.error(e)
        })
    })
  }

  parseData(loaded) {
    const series = []
    loaded.forEach((part) => part.forEach((sample) => {
      for (let key in series) {
        if (series[key][0] === sample[0]) {
          series[key][1] += sample[1]
          return
        }
      }
      series.push(sample)
    }))

    const growth = series.reduce((acc, next, index) => {
      if (index === 0) {
        return [[series[0][0], 0]]
      }
      const prev = series[index - 1][1]
      return [...acc, [series[index][0], Math.round(((series[index][1] - prev)) * 100) / 100]]
    }, [])

    const state = {
      series: series,
      avg: series.reduce((acc, next) => acc + next[1], 0) / series.length,
      growth: growth
    }

    state.series.sort((a, b) => a[0] - b[0])
    state.growth.sort((a, b) => a[0] - b[0])

    // If there are no data in series then do not update the setState - setting empty will trigger update and thus loop
    if (series.length !== 0) {
      this.setState(state)
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.fetchData()
    }
  }

  render() {
    if (this.state.series.length === 0) {
      return null
    }

    return (
      <Grid container style={{marginBottom: '12px'}}>
        <Grid item xs={6}>
          <Grid container justify={"center"}>
            <Grid item xs={12}>
              <Grid container justify={"center"}>
                <HighChartsComponent
                  width={this.props.containerWidth}
                  title={this.props.title}
                  series={this.state.series}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={"center"}>
                  <Typography variant="body2">
                    Average: {numeral(this.state.avg).format('0.00a')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify={"center"}>
            <Grid item xs={12}>
              <Grid container justify={"center"}>
                <HighChartsComponent
                  width={this.props.containerWidth}
                  title={`${this.props.title} change by day`}
                  series={this.state.growth}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
