import React, {useState} from 'react';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import {styled} from "@mui/material/styles";
import {Box, FormControl, InputLabel, ListItemButton, MenuItem, Select} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";

const pages = [
  {page: '/', title: 'Overview'},
  {page: '/hydra', title: 'ORY Hydra'},
  {page: '/keto', title: 'ORY Keto'},
  {page: '/kratos', title: 'ORY Kratos'},
  {page: '/oathkeeper', title: 'ORY Oathkeeper'},
  {page: '/github', title: 'ORY Github'},
]

const drawerWidth = 240;

const RootStyled = styled('div')({
  display: 'flex'
});

const ToolbarStyled = styled(Toolbar)({
  paddingRight: 24
});

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "variant"
})(({ theme, variant }) => ({
  position: "absolute",
  ...(variant.includes("appBar") && {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  ...(variant.includes("appBarShift") && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "variant2"
})(({ theme, variant2 }) => ({
  ...(variant2.includes("paper") && {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  }),
  ...(variant2.includes("drawerPaper") && {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  })
}));

const MainStyled = styled("div")(({theme}) => ({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
  paddingTop: theme.spacing(4)
}));

const ListStyled = styled(List)({
  width: drawerWidth
});

const AppBarSpacer = styled("div")(
  ({theme}) => (theme.mixins.toolbar));

const generateMenuItems = () => {
  const rows = [];
  let dt = new Date()

  for (let year = dt.getFullYear(); year >= 2020; year--) {
    let startMonth = (year === 2020) ? 3 : 1
    let endMonth = (year === dt.getFullYear()) ? dt.getMonth() + 1 : 12

    for (let month = endMonth; month >= startMonth; month--) {
      const date = new Date(year, month - 1)
      rows.push({year: year, month: month, monthName: date.toLocaleString('default', { month: 'long' })})
    }
  }

  return rows
}

const Layout = ({children}) => {
  const location = useLocation();
  const navigate = useNavigate();

  let showDate;
  if (location.state && location.state.date !== undefined) {
    showDate = location.state.date
  } else {
    // Date months starts from zero, so add one to have 1..12 like files are named
    let dt = new Date()
    showDate = `${dt.getFullYear()}-${dt.getMonth() + 1}`
  }

  const [Value, setValue] = useState(showDate);
  const menuItems = generateMenuItems()

  return (
    <RootStyled>
      <CssBaseline/>
      <AppBarStyled variant={clsx("appBar", "appBarShift")}>
        <ToolbarStyled>
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            ORY Telemetry Dashboard
          </Typography>
        </ToolbarStyled>
      </AppBarStyled>
      <DrawerStyled variant="permanent" variant2={clsx("paper", "drawerPaper")} open>
        <ListStyled>
          {pages.map(({page, title}) => (
            <ListItemButton key={page}
                            selected={page === window.location.pathname}
                            onClick={() => {
                              // If location state date exists then lets use the same date
                              if (location.state && location.state.date !== undefined) {
                                navigate(page, { replace: true, state: location.state })
                              } else {
                                navigate(page)
                              }
                            }}>
              <ListItemText primary={title}/>
            </ListItemButton>
          ))}
        </ListStyled>

        <Box sx={{ p: 1 }}>
          <FormControl>
            <InputLabel id="test-select-label">Date</InputLabel>
            <Select label="Label" value={Value} sx={{
              marginTop:0,
              width: 200,
              height: 40
            }} MenuProps={{
              style: { maxHeight: 300, }
            }} onChange={(selected) => {
              setValue(selected.target.value)
              navigate(window.location.pathname, { replace: true, state: {date: selected.target.value} })
            }}>
              {menuItems.map(({year, month, monthName}) => (
                <MenuItem key={`${year}-${month}`} value={`${year}-${month}`}>
                  {year} {monthName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DrawerStyled>
      <MainStyled>
        <AppBarSpacer />
        {children}
      </MainStyled>
    </RootStyled>
  )
}

export default Layout
